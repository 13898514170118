h3, h4, h5{
  font-family: "Kalam";
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
}

body {
  background-image: url("../img/bg2.jpg");
  background-repeat: repeat;
}

a {
  padding-left: 0px !important;
}

#logo-frame{
  max-width: 80vh;
}

#about-frame{
  width: 45vw;
}

.link-title-cartoon{
  cursor: pointer;
}

.card-cartoon{
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  border: 2px solid black;
}