.admin-todo{
  border: 2px solid black;
  background-color: #ddd;
}

.admin-text{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 26px;
}

.admin-green{
  color: #3b864a;
}

.admin-todo:nth-child(2){
  background-color: #bbb;
}

.admin-grey{
  color: #6b6b6b;
  font-size: 20px;
}